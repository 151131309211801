import * as React from "react"
import {graphql} from "gatsby"
import PropTypes from "prop-types"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import VideoCardGrid from "../cms/VideoCardGrid"

const getVideoId = (url) => {
    // extract video id from YouTube URL
    // NOTE: used this code https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
    const match = url.match(regExp)
    return (match && match[2].length === 11) ? match[2] : null
}

const YoutubeSection = ({section, className=""}) => {
    const {heading, videos} = section
    const videosInfo = (videos || []).map(video => { // noinspection JSCheckFunctionSignatures
        return {
            title: video.title,
            url: video.url,
            image: video?.thumbnail?.gatsbyImageData ?
                <GatsbyImage
                    image={getImage(video.thumbnail.gatsbyImageData)}
                    alt={video.title}
                    className="w-full aspect-video"/> :
                <img
                    alt={video.title} loading={"lazy"}
                    className={"w-full aspect-video"}
                    src={`https://img.youtube.com/vi/${getVideoId(video.url)}/0.jpg`}/>
        }
    })

    return (<section className={className||""}>
        <h2 className="text-2xl font-bold text-center my-2">{heading}</h2>
        <VideoCardGrid videos={videosInfo}/>
    </section>)
}

YoutubeSection.propTypes = {
    section: PropTypes.shape({
        heading: PropTypes.string,
        videos: PropTypes.arrayOf(PropTypes.shape({
            title: PropTypes.string,
            url: PropTypes.string,
            thumbnail: PropTypes.shape({
                url: PropTypes.string,
                gatsbyImageData: PropTypes.object
            })
        }))
    }),
    className: PropTypes.string
}

YoutubeSection.defaultProps = {
    section: {
        heading: "",
        videos: []
    },
    className: ""
}

export const query = graphql`
    fragment YoutubeSection on STRAPI__COMPONENT_SECTION_YOUTUBE_VIDEOS {
        id
        heading
        strapi_component
        videos {
            title
            url
        }
    }
`

export default YoutubeSection
