import * as React from "react"
import { graphql } from "gatsby"
import CmsLocationPageContent from "../components/cms/CmsLocationPageContent"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import PropTypes from "prop-types"

const CmsPage = ({ data }) => {
  let page = data.page
  const slug = (data.page.blog === true ? "blog/" : "") + data.page.slug
  return (
    <Layout
      breadcrumbs={true}
      canonical={page.canonical_url || slug}
      title={page.title}
      className=""
    >
      <Seo
        title={page.meta_title}
        canonical={page.canonical_url || slug}
        description={page.meta_description}
        article={page}
        noindex={page.noindex === true}
      />
      <div className="flex flex-col">
        <CmsLocationPageContent page={page} relatedPages={data.relatedPages} />
      </div>
    </Layout>
  )
}
CmsPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query CmsLocationPageQuery($pageId: String!, $location: String) {
    page: strapiPage(id: { eq: $pageId }) {
      title
      blog
      html
      meta_title
      meta_description
      noindex
      slug
      canonical_url
      hero_image {
        url
      }
      tag {
        brand
        watches
        jewelry
        location
      }
      hero_image_url
      created_at
      updated_at
      updated_at_formatted: updated_at(formatString: "MMMM Do, YYYY")
      blocks {
        id
        heading
        block
        itemType
        image {
          url
        }
        content
        videos {
          title
          url
          id
        }
        description: html
        strapi_component
        categories {
          parent
          prioroty
          gallery {
            url
            image
            name
            totalCount
          }
          category {
            name
            description
            bottom_description
            h1_header
            helper
          }
        }
      }
    }
    otherTaggedLocationPages: allStrapiPage(filter: { tag: { location: { regex: $location } } }) {
      nodes {
        slug
        title
        meta_title
        tag {
          brand
          watches
          jewelry
          location
        }
      }
    }
    relatedPages: allStrapiPage(filter: { title: { regex: $location } }) {
      nodes {
        slug
        canonical_url
        title
        meta_title
      }
    }
  }
`

// blocks {
//   __typename
//   categories {
//     __typename
//     gallery {
//       name
//       image
//       url
//     }
//     category {
//       name
//     }
//   }
//   heading
// }

export default CmsPage
