import * as React from "react"
import PropTypes from "prop-types"
import YoutubeSection from "./YoutubeSection"
import BrandsWeBuy from "../common/BrandsWeBuy"
import HowItWorks from "../common/HowItWorks"
import Map from "../common/Map"
import Testimonials from "../common/Testimonials"
import AdditionalForms from "../common/AdditionalForms"
import HaveQuestions from "../common/HaveQuestions"
import BrandGallery from "./BrandGallery"
import WhatAreYourOptions from "../common/WhatAreYourOptions"
import FormBlock from "./FormBlock"

import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {getGatsbyImageHero} from "../../helpers/GatsbyImageHelper";

const Section = ({ section, className = "" }) => {
  const { strapi_component } = section
  switch (strapi_component) {
    case "blocks.brand-gallery":
      return <BrandGallery section={section} className={className} />
    case "blocks.image-text-block":
      return (
        <section
          className={
            className || "bg-[#f6ebd0] flex flex-col justify-center items-center py-10 relative"
          }
        >
          <div className="w-11/12 max-w-[1366px] mx-auto flex flex-col md:flex-row gap-5 ">
            <div className="w-full max-w-[450px] h-full aspect-square lg:sticky lg:top-20">
              <GatsbyImage
                alt={section.heading || "Sell Us Your Jewelry"}
                className={className}
                image={getImage(getGatsbyImageHero(section.image.url))}
              />
            </div>
            <div className="w-full leading-loose" dangerouslySetInnerHTML={{ __html: section.content }} />
          </div>
        </section>
      )
    case "blocks.text-block":
      return (
        <section className={className || "cms mx-4 md:mx-auto"}>
          <h2 className={"px-10  py-10 text-center text-4xl uppercase lg:py-10"}>
            {section.heading || "Our recent reviews"}
          </h2>
          <div dangerouslySetInnerHTML={{ __html: section.description }} />
        </section>
      )
    case "blocks.form-block":
      return <FormBlock section={section} className={className} heading={section.heading} />
    case "blocks.youtube-videos":
      return <YoutubeSection section={section} className={className} />
    case "blocks.template-block":
      switch (section.block) {
        case "BrandsWeBuy":
          return <BrandsWeBuy className={className} />
        case "WhatAreYourOptions":
          return <WhatAreYourOptions heading={section.heading} className={className} />
        case "HaveQuestions":
          return <HaveQuestions heading={section.heading} className={className} />
        case "HowItWorks":
          return (
            <HowItWorks
              heading={section.heading}
              boxClassName={
                "hoverScale flex-grow-0 text-link text-center md:w-2/5 xl:w-auto xl:flex-1"
              }
              arrowClassName={"hidden flex-shrink self-center xl:block"}
              className={className}
            />
          )
        case "Map":
          return <Map />
        case "Testimonials":
          return (
            <section className={className || "container mx-auto visibility-auto"}>
              <h2 className={"px-10  py-10 text-center text-4xl uppercase lg:py-10"}>
                {section.heading || "Our recent reviews"}
              </h2>
              <Testimonials slides={{ xs: 1, "2xl": 2 }} />
            </section>
          )
        case "WhatAreYouLookingToSell":
          return (
            <AdditionalForms
              //className=""
              headerClassName="p-5 text-center text-3xl uppercase w-full flex-shrink-0 flex-grow"
              gridClassName="mx-auto flex flex-row flex-wrap w-full gap-4 p-5"
              watches="How much is your watch worth?"
              diamonds="How much is your diamond worth?"
              jewelry="How much is your jewelry worth?"
              silver="How much is your silver worth?"
              //buttonClassName="bg-gray-500 text-white font-bold text-sm py-3 rounded-lg hover:-translate-y-1 duration-150 hover:shadow-lg"
            />
          )
        default:
          return null
      }
    default:
      return null
  }
}

Section.propTypes = {
  section: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.string,
    videos: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    strapi_component: PropTypes.string,
    image: 
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    block: PropTypes.string,
    description: PropTypes.string
  }),
  className: PropTypes.string,
}

Section.defaultProps = {
  section: {
    heading: "",
    content: "",
    strapi_component: "",
  },
}

export default Section
