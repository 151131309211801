import * as React from "react"
import { GatsbyImage,  getImage } from "gatsby-plugin-image"
import { getGatsbyImageHero } from "../../helpers/GatsbyImageHelper"
import PropTypes from "prop-types"


const HeroImage = ({
  page,
  className = "h-full w-full object-cover hover:scale-105 duration-300 ease-in-out",
}) => {
  return (
    (page.hero_image_url || page.hero_image?.url) && (
      <GatsbyImage
        alt={page.meta_title}
        className={className}
        image={getImage(getGatsbyImageHero(page.hero_image_url || page.hero_image.url))}
      />
    )
  )
}
HeroImage.propTypes = {
  page: PropTypes.shape({
    hero_image_url: PropTypes.string,
    hero_image: PropTypes.shape({
      url: PropTypes.string,
    }),
    meta_title: PropTypes.string,
    updated_at_formatted: PropTypes.string,
    title: PropTypes.string,
  }),
  className: PropTypes.string,
}

export default HeroImage
