import * as React from "react"
import Section from "./Section"
import { graphql, useStaticQuery } from "gatsby"
import HeroImage from "./HeroImage"
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import FeaturedProductsBlock from "../products/FeaturedProductsSlider"

const CmsLocationPageContent = ({ page, relatedPages }) => {
  const blocks = page.blocks
  const tag = page.tag
  const locationCategory = tag?.watches === true ? 'watch' : 'jewelry'
  
  const { watches, jewelry, watchProducts, jewelryProducts } = useStaticQuery(
    graphql`
      query CombinedLocationPageStaticQuery {
        watchProducts: allStrapiProduct(
          sort: { fields: stock_date, order: DESC }
          limit: 12
          filter: { item_type: { eq: "Watch" } }
        ) {
          nodes {
            name
            productType
            categories {
              name
              id
            }
            url_key
            sku
            title
            image {
              name
              url
            }
            thumbnail {
              name
              url
            }
            brand
            model
            price
          }
        }
        jewelryProducts: allStrapiProduct(
          sort: { fields: stock_date, order: DESC }
          limit: 12
          filter: { item_type: { eq: "Jewelry" } }
        ) {
          nodes {
            id
            name
            productType
            categories {
              name
              id
            }
            url_key
            sku
            title
            image {
              name
              url
            }
            thumbnail {
              name
              url
            }
            brand
            model
            price
          }
        }
        watches: allStrapiPage(
          filter: { tag: { watches: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
        jewelry: allStrapiPage(
          filter: { tag: { jewelry: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
      }
    `
  )
  const products = locationCategory === "jewelry" ? jewelryProducts : watchProducts
  console.log(products)
  products.nodes.forEach(product => {
    product.url = "#quote-form"
  })
  const relatedPagesList = relatedPages?.nodes ? `
<h2 className="">
Read more about luxury ${locationCategory} buyers around ${page.tag.location}</h2>
<ul class="list list-disc ml-6">
<li>${relatedPages.nodes.map(page => `<a href="/${page.slug}/">${page.title}</a>`).join("</li><li>")}</li>
</ul>` : ''
  blocks.forEach(block => {
    if (block.strapi_component === "blocks.image-text-block") {
      block.content = block.content.replace(/\[RELATED_PAGES\]/g, relatedPagesList)
    } else if(block.strapi_component === "blocks.text-block") {
      block.description = block.description.replace(/\[RELATED_PAGES\]/g, relatedPagesList)
    }
  })
  return (
    <>
      <section className="w-10/12 mx-auto max-w-[1366px] grid md:grid-cols-2 gap-4 py-5 lg:py-10">
        <div className="w-full flex justify-center items-center">
          <HeroImage page={page} />
        </div>
        <div className=" w-full flex flex-col gap-5">
          <h1>{page.title}</h1>
          <section className={"cms md:mx-auto"} dangerouslySetInnerHTML={{ __html: page.html }} />
          <a
            href="#quote-form"
            className="max-w-max font-bold text-black bg-accentMd px-5 py-3 rounded-full duration-200 hover:text-white border-b-2 border-accent hover:shadow-lg hover:-translate-y-1"
          >
            {locationCategory === "jewelry" ? "QUOTE MY JEWELRY" : "QUOTE MY WATCH"}
          </a>
        </div>
      </section>
      <section className="w-full bg-black text-white uppercase my-10">
        <div className="w-10/12 mx-auto max-w-[1366px] text-white py-10">
          <h2 className="">{locationCategory === "jewelry" ? "JEWELRY" : "WATCH"} BRANDS WE BUY</h2>
          <ul
            className={`columns-2 lg:columns-3 gap-x-4 pb-5 ${
              (locationCategory === "jewelry" && jewelry.nodes.length > 36) ||
              (locationCategory === "watches" && watches.nodes.length > 36)
                ? "xl:columns-4"
                : ""
            }`}
          >
            {locationCategory === "jewelry"
              ? jewelry.nodes.map(jewelryItem => {
                return (
                  <Link
                    key={jewelryItem.slug}
                    className="max-w-max py-3 text-white md:text-lg md:hover:text-accentMd md:hover:translate-x-1 duration-200 block"
                    to={`/${jewelryItem.slug}/`}
                  >
                    {jewelryItem.tag.brand}
                  </Link>
                )
              })
              : watches.nodes.map(watches => {
                return (
                  <Link
                    key={watches.slug}
                    className="max-w-max py-3 text-white md:text-lg md:hover:text-accentMd md:hover:translate-x-1 duration-200 block"
                    to={`/${watches.slug}/`}
                  >
                    {watches.tag.brand}
                  </Link>
                )
              })}
          </ul>
          <a
            href="#quote-form"
            className="font-bold text-black bg-accentMd px-5 py-3 rounded-full mt-5 duration-200 hover:text-white border-b-2 border-accent hover:border-white hover:-translate-y-1"
            to="/brands-we-buy/"
          >
            {locationCategory === "jewelry" ? "GET JEWELRY QUOTE" : "GET WATCH QUOTE"}
          </a>
        </div>
      </section>
      <section className="w-11/12 max-w-[1366px] mx-auto flex justify-center items-center py-10 px-5">
        <FeaturedProductsBlock
          products={products.nodes}
          to={"#quote-form"}
          buttonText="GET QUOTE"
          title={`RECENTLY PURCHASED ${locationCategory}`}
        />
      </section>
      {blocks.length > 0
        ? blocks.map(block => (
          <Section key={`${block.strapi_component}-${block.id}`} section={block} />
        )) : null}
    </>
  )
}
CmsLocationPageContent.propTypes = {
  page: PropTypes.object.isRequired,
  relatedPages: PropTypes.object.isRequired,
}

export default CmsLocationPageContent
