import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import SideQuoteForm from "../layout/SideQuoteForm"
import PropTypes from 'prop-types'


const FormBlock = ({ section, heading }) => {
  const itemType = (section.itemType || "jewelry").toLowerCase()
  return (
    <section
      id="quote-form"
      className="pt-16 w-11/12 mx-auto max-w-[1366px] flex flex-col lg:flex-row"
    >
      <div className="w-full  mx-auto max-w-[600px] text-center">
        <h1 className="w-full text-3xl uppercase pt-3">
          {heading
            ? heading
            : `
          WHAT IS MY ${itemType.toUpperCase()} WORTH?`}
        </h1>
        <p>{section.prompt ?? `Fill out this simple form to receive a free ${itemType} quote!`}</p>
        {itemType === "jewelry" ? (
          <StaticImage
            src={"../images/sell-my-jewelry-form-header.png"}
            alt={"Watch Quote Form image"}
            loading={"lazy"}
            placeholder={"none"}
            className="w-11/12 mx-auto "
          />
        ) : (
          <StaticImage
            src={"../images/sell-my-watch-form-header.png"}
            alt={"Watch Quote Form image"}
            loading={"lazy"}
            placeholder={"none"}
            className="w-11/12 mx-auto "
          />
        )}
      </div>
      <SideQuoteForm defaultType={itemType} className="w-11/12 max-w-[800px] mx-auto" />
    </section>
  )
}
FormBlock.propTypes = {
  section: PropTypes.object,
  heading: PropTypes.string,
  className: PropTypes.string,
}
export default FormBlock
